import React, { useState, useContext, Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Accordion, useAccordionButton, AccordionContext, ListGroup } from 'react-bootstrap';
import Icon from '@mdi/react';
import { mdiCircleOutline, mdiCircle } from '@mdi/js';
const ContextAwareToggle = ({ children, eventKey, callback }) => {
  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));
  const isCurrentEventKey = activeEventKey === eventKey;
  return (
    <Fragment>
      <Link
        to="#"
        onClick={decoratedOnClick}
        aria-expanded={isCurrentEventKey}
        className="d-flex align-items-center text-inherit text-decoration-none h4 mb-0"
        data-bs-toggle="collapse"
        aria-controls="courseTwo"
      >
        <div className="me-auto">{children.title}</div>
        <span className="chevron-arrow ms-4">
          <i className="fe fe-chevron-down fs-4"></i>
        </span>
      </Link>
    </Fragment>
  );
};
const GKAccordionDefault = ({
  accordionItems = [],
  itemClass,
  courseId,
  completedLessons = [],
  currentLessonId,
  hasActiveSubscription,
}) => {
  const [activeSection, setActiveSection] = useState(accordionItems[0]?.id);

  useEffect(() => {
    const currentSection = accordionItems.find(item =>
      item.topics.some(topic => topic.id === currentLessonId)
    );
    if (currentSection) {
      setActiveSection(currentSection.id);
    }
  }, [currentLessonId, accordionItems]);
  return (
    <Accordion activeKey={activeSection} onSelect={setActiveSection}>
      <ListGroup as="ul" variant="flush">
        {accordionItems.map((item, index) => (
          <ListGroup.Item
            key={index}
            as="li"
            className={`${itemClass ? itemClass : ''}`}
          >
            <ContextAwareToggle eventKey={item.id}>
              {item}
            </ContextAwareToggle>
            <Accordion.Collapse eventKey={item.id}>
              <ListGroup className="py-4" as="ul">
                {(item.topics || []).map((subitem, subindex) => {
                  const isCompleted = completedLessons.includes(subitem.id);
                  const isCurrentLesson = subitem.id === currentLessonId;
                  const isLocked = hasActiveSubscription ? false : subitem.locked;
                  return (
                    <ListGroup.Item
                      key={subindex}
                      as="li"
                      className={`px-0 py-1 border-0 ${
                        isCurrentLesson ? 'shadow rounded border-start border-3 border-primary' : ''
                      }`}
                      style={isCurrentLesson ? { backgroundColor: '#ced4da' } : {}}
                    >
                      <Link
                        to={`/courses/${courseId}/${subitem.id}`} // after subscription API is implemented, return here 
                        className={`d-flex justify-content-between align-items-center text-inherit text-decoration-none ${
                          isLocked ? 'text-muted' : ''
                        }`}
                        style={{
                          whiteSpace: 'normal',
                          wordWrap: 'break-word',
                          overflowWrap: 'anywhere',
                        }}
                      >
                        <div className="text-truncate">
                          <span
                            className="icon-shape icon-sm rounded-circle me-2"
                            style={{ backgroundColor: 'transparent' }}
                          >
                            {isLocked ? (
                              <i className="fe fe-lock fs-3"></i>
                            ) : isCompleted ? (
                              <Icon path={mdiCircle} size={1} color="#6A5ACD" />
                            ) : (
                              <Icon path={mdiCircleOutline} size={1} color="#6A5ACD" />
                            )}
                          </span>
                          <span className="fs-5">{subitem.topic}</span>
                        </div>
                      </Link>
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            </Accordion.Collapse>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </Accordion>
  );
};
export default GKAccordionDefault;