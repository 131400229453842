import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { buildCourseImageURL } from 'utils/buildCourseImageURL';
import CardLevelIcon from 'components/common/miscellaneous/CardLevelIcon';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Content from 'api/Content';
import mixpanel from "analytics/mixpanel";

const CourseCard = ({ course }) => {
    const { t } = useTranslation();
    const { id: courseId, title, level = "Beginner", description } = course;
    const imageUrl = buildCourseImageURL(courseId);
    const navigate = useNavigate();
    
    const [lessonCount, setLessonCount] = useState(0);

    useEffect(() => {
        const fetchLessonCount = async () => {
            try {
                const data = await Content.get(`/courses/${courseId}/lessons`);
                const totalLessons = (data.sections || []).reduce(
                    (acc, section) => acc + (section.lessons?.length || 0),
                    0
                );
                setLessonCount(totalLessons);
            } catch (error) {
                console.error("Error fetching lesson count:", error);
            }
        };

        fetchLessonCount();
    }, [courseId]);

    const handleButtonClick = (e) => {
        e.stopPropagation();
        
        mixpanel.track('Button Click', {
            button: 'Go To Course Button from Dashboard Clicked',
            course_id: courseId,
        });

        navigate(`/courses/${courseId}`);
    };

    return (
        <div
            className="card shadow-sm course-card"
            style={{ borderRadius: '2px', overflow: 'hidden', height: '450px' }} 
        >
            <img
                src={imageUrl}
                className="card-img-top"
                alt={title}
                style={{ height: '180px', objectFit: 'cover', borderTopLeftRadius: "2px", borderTopRightRadius: "2px" }}
            />
            <div className="card-body d-flex flex-column" style={{ minHeight: '100px' }}>
                <h3 className="card-title mb-2">{title}</h3>
                <p 
                    className="card-text" 
                    style={{ fontSize: "14px", color: "#666", margin: "2px 0" }}
                >
                    {description}
                </p>
                <div className="d-flex justify-content-center mt-auto">
                    <button 
                        className="btn btn-primary btn-sm w-100"
                        onClick={handleButtonClick}
                    >
                        {t('learn_more')}
                    </button>
                </div>
            </div>
            <div className="card-footer d-flex align-items-center py-2">
                <span className="d-flex align-items-center me-1">
                    <CardLevelIcon level={level} /> 
                    <span className="text-muted ms-1" style={{ fontSize: "14px" }}>
                        {level}
                    </span>
                </span>
                <span className="d-flex align-items-center ms-auto">
                    <i className="bi bi-journal-text me-1" style={{ fontSize: "14px" }}></i>
                    <span className="text-muted" style={{ fontSize: "14px" }}>
                        {lessonCount} {t('lessons')}
                    </span>
                </span>
            </div>
        </div>
    );
};

export default CourseCard;