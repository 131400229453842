import axios from "axios";
import { getAccessToken, logout } from "components/auth/Authenticated";

const API = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, // Base URL
  timeout: 10000, // Timeout
  withCredentials: true, // Include cookies
});

// Add an interceptor to handle errors globally
API.interceptors.response.use(
  (response) => response.data, // Return data directly
  (error) => {
    if (error.response) {
      // Server responded with an error
      console.error(`Error ${error.response.status}:`, error.response.data);
      if (error.response.status === 401) {
        // Unauthorized, logout and reload the page
        logout();
        window.location.reload(); // Reload the page to clear the state
      }
    } else {
      console.error("Network/Timeout error:", error.message);
    }
    return Promise.reject(error); // Propagate the error
  }
);

// Add an interceptor to include the access token in the Authorization header
API.interceptors.request.use((config) => {
  const accessToken = getAccessToken();
  if (accessToken) {
    config.headers['Authorization'] = accessToken;
  }
  return config;
});

export default API;