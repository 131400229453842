import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { displayPrice } from 'helper/priceHelpers';
import { useSessionStorage } from 'react-use';
import mixpanelInstance from 'analytics/mixpanel';

const PlanCard = ({ plan, durationLabel, durationDays, isActive, expiryDate, hasActiveSubscription }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [, setSessionPlan] = useSessionStorage('selectedPlan');

  const handleBuyClick = () => {
    const selectedPlan = {
      ...plan,
      duration: durationDays,
    };

    mixpanelInstance.track('Buy Plan Clicked', {
      plan_id: plan.id,
      plan_name: plan.name,
    });
    
    setSessionPlan(selectedPlan);
    setTimeout(() => {
      navigate('/payment');
    }, 0); // Navigate after setting the session plan
  };

  const renderFeatures = (features) => (
    <ul className="list-unstyled">
      {features.map((feature, index) => (
        <li key={index} className="d-flex align-items-center mb-2">
          <span className="text-primary me-1">&#10003;</span>
          {feature}
        </li>
      ))}
    </ul>
  );

  const renderSubscriptionInfo = () => (
    <div className="alert alert-success text-center p-3 mb-4">
      <h5 className="fw-bold mb-2">{t('you_have_active_subscription')}</h5>
      <p className="mb-0">
        <span className="fw-bold">{t('expiry_date')}:</span> {new Date(expiryDate).toLocaleDateString()}
      </p>
    </div>
  );

  const renderBuyButton = () => (
    <button
      className="btn btn-outline-primary w-100 mb-4"
      onClick={handleBuyClick}
      disabled={hasActiveSubscription}
    >
      {t('buy_plan')}
    </button>
  );

  return (
    <div className="card border-light shadow-sm p-1 mb-4 bg-white" style={{ width: '30rem' }}>
      <div className="card-body text-center">
        <h5 className="card-title mb-3" style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
          {plan.name}
        </h5>
        <p className="text-muted mb-4" style={{ fontSize: '0.9rem' }}>
          {plan.description}
        </p>
        <h2 className="fw-bold mb-4">
          {displayPrice(plan.price?.amount)}
          <small className="text-muted fs-6">
            {plan.price?.currency} / {durationLabel}
          </small>
        </h2>

        {isActive ? renderSubscriptionInfo() : renderBuyButton()}

        <div className="text-start">
          <h6 className="fw-bold">{t('plan_includes')}</h6>
          {renderFeatures(plan.features || [])}
        </div>
      </div>
    </div>
  );
};

export default PlanCard;