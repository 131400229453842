import React from 'react';
import TelegramLogin from 'components/auth/telegram/Telegram';
import Auth0Login from 'components/auth/auth0/Auth0';
import AuthPage from './AuthPage';
import Cookies from 'js-cookie';
import { useSessionStorage } from 'react-use-storage';
import mixpanelInstance from 'analytics/mixpanel'; 

const userIDKey = 'user_id';
const accessTokenKey = 'access_token';
const authMethodKey = 'auth_method';

const Authenticated = ({ Component }) => {
  const [method, setMethod] = useSessionStorage(authMethodKey);

  const user = getUser();
  if (user) {
    return <Component />;
  }

  const onSuccess = (userID, accessToken) => {
    // 6.90 days because the cookie for the access token expires in 7 days.
    // Access token cookie is set by the backend server on successful login regardless of the login method.
    const expires = 6.90 
    Cookies.set(userIDKey, userID, { expires: expires, sameSite: 'strict', secure: true, path: '/' });
    localStorage.setItem(accessTokenKey, accessToken);

    // Identify the user in Mixpanel
    mixpanelInstance.identify(userID);  

    setMethod(null);
  };

  const onFailure = (error) => {
    console.error('Error logging in:', error);
    setMethod(null);
  };

  if (method === 'telegram') {
    return <TelegramLogin onSuccess={onSuccess} onFailure={onFailure} />;
  } else if (method === 'auth0') {
    return <Auth0Login onSuccess={onSuccess} onFailure={onFailure} />;
  }

  return <AuthPage setMethod={setMethod} />;
};

const getUser = () => {
  const userID = Cookies.get('user_id');
  if (userID) {
    return { id: userID };
  }
  return null;
};

const getAccessToken = () => {
  return localStorage.getItem(accessTokenKey);
};

const logout = () => {
  // Reset Mixpanel user
  mixpanelInstance.reset(); 

  Cookies.remove('user_id');
  Cookies.remove('telegram_token');
  Cookies.remove('bearer_token');
  localStorage.removeItem(accessTokenKey);
};

export default Authenticated;
export { getUser, getAccessToken, logout };