import ReactGA from 'react-ga4';

let isInitialized = false;

// Singleton for GA initialization
const initializeAnalytics = () => {
  if (isInitialized) {
    return; // Prevent re-initialization
  }

  const measurementId = process.env.REACT_APP_GA_MEASUREMENT_ID;

  if (!measurementId) {
    console.warn("GA Measurement ID is not defined.");
    return;
  }

  ReactGA.initialize(
    [
      {
        trackingId: measurementId, // Correct way to pass the measurement ID
      }
    ],
    {
      testMode: process.env.NODE_ENV !== 'production', // Prevents sending data in non-production
    }
  );

  isInitialized = true; // Mark as initialized
  console.log("Google Analytics initialized");
};

const trackPageView = (path) => {
  if (path) {
    ReactGA.send({ hitType: 'pageview', page: path });
  }
};

export { initializeAnalytics, trackPageView };
