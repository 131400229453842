import { useState, useEffect } from 'react';
import API from 'api/API';

const useFetchSubscriptions = (user) => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        setLoading(true);
        const response = await API.get(`/subscription/api/v1/subscriptions/${user.id}/active`);
        setSubscriptions(response || []); // Ensure it defaults to an array
      } catch (err) {
        console.error('Failed to fetch subscriptions:', err);
        setError(err);
        setSubscriptions([]);
      } finally {
        setLoading(false);
      }
    };

    if (user) {
      fetchSubscriptions();
    } else {
      // no subscriptions if no user
      setLoading(false);
    }
  }, []);

  return { subscriptions, loading, error };
};

export default useFetchSubscriptions;