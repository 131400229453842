import axios from "axios";
import { useTranslation } from "react-i18next";

const TelegramLogin = ({ onSuccess, onFailure }) => {
    const { t } = useTranslation(); 

    const authToken = getTelegramAuthToken();
    if (authToken) {
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/api/v1/users/login`, {}, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Telegram ${authToken}`
            }
        })
            .then((res) => {
                const userID = res.data.id;
                const accessToken = res.headers.getAuthorization();
                onSuccess(userID, accessToken);

                window.location.hash = "";
            })
            .catch((error) => {
                onFailure(error);
            });
    } else {
        initiateLogin();
    }

    return <div>{t('loggingIn')}</div>;
};

function initiateLogin() {
    const origin = encodeURIComponent(window.location.origin);
    const authUrl = `https://oauth.telegram.org/auth?bot_id=${process.env.REACT_APP_TELEGRAM_LOGIN_BOT_ID}&origin=${origin}&return_to=${window.location.href}`;
    window.location.href = authUrl;
}

function getTelegramAuthToken() {
    // Check if the URL has a hash part
    if (!window.location.hash) {
        return null;
    }

    // Get the hash part of the URL (everything after the #)
    const hash = window.location.hash.substring(1); // Remove the #

    // Parse the hash parameters
    const params = new URLSearchParams(hash);

    // Extract the value of tgAuthResult
    return params.get("tgAuthResult");
}

export default TelegramLogin;