import { v4 as uuid } from 'uuid';

const NavbarDefaultRoutes = [
  {
    id: uuid(),
    menuitem: "Kurslar",
    link: "/", 
  },
  {
    id: uuid(),
    menuitem: "Jamoa",
    link: "https://xorazmiy.dev/#team", 
  },
  {
    id: uuid(),
    menuitem: "Tarif Rejalar",
    link: "/pricing", 
  },
  {
    id: uuid(),
    menuitem: "FAQ",
    link: "https://xorazmiy.dev/#faq", 
  },
];

export default NavbarDefaultRoutes;