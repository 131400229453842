import mixpanel from "mixpanel-browser";

// Use a singleton pattern to initialize Mixpanel once
const mixpanelInstance = (() => {
  const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN;

  if (!mixpanelToken) {
    console.warn("Mixpanel token is not defined.");
    return null;
  }

  mixpanel.init(mixpanelToken, {
    cookie_domain: '.xorazmiy.dev',
    cross_subdomain_cookie: true,
    persistence: 'cookie',
    test: process.env.NODE_ENV !== "production", // Mark events as test data in non-production
    debug: process.env.NODE_ENV !== "production", // Enable debug only in non-production
  });

  return mixpanel; 
})();

export default mixpanelInstance;