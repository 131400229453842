import { useState, useEffect } from 'react';
import Content from 'api/Content';

const useLessonContent = (courseId, lessonId) => {
  const [lessonContent, setLessonContent] = useState('');
  const [contentError, setContentError] = useState(null);

  useEffect(() => {
    if (!lessonId) return;

    const controller = new AbortController();

    const fetchLessonContent = async () => {
      try {
        const data = await Content.get(`/courses/${courseId}/lessons/${lessonId}/content`);
        setLessonContent(data);
        setContentError(null);
      } catch (error) {
        if (error.name !== 'AbortError') {
          setContentError(error.message);
        }
      }
    };

    fetchLessonContent();

    return () => {
      controller.abort();
    };
  }, [lessonId]);

  return { lessonContent, contentError };
};

export default useLessonContent;