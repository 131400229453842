import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAuthTokens from '../components/auth/auth0/useAuthTokens';
import Content from 'api/Content';
import API from 'api/API';

const useLessonProgress = (courseId, userId) => {
  const { t } = useTranslation();
  const [lessonIdToNavigate, setLessonIdToNavigate] = useState(null);
  const [buttonText, setButtonText] = useState(t('start_learning'));
  const { accessToken } = useAuthTokens();

  useEffect(() => {
    const fetchLessonsAndProgress = async () => {
      try {
        const lessonsData = await Content.get(`/courses/${courseId}/lessons`);
        const lessonsArray = lessonsData.sections?.flatMap(section => section.lessons) || [];

        if (lessonsArray.length === 0) {
          console.warn('No lessons found for this course.');
          return;
        }

        if (!userId) {
          setLessonIdToNavigate(lessonsArray[0]?.id || null);
          setButtonText(t('start_learning'));
          return;
        }

        try {
          const progressData = await API.get(`/users/api/v1/users/${userId}/courses/${courseId}`);
          const completedLessonIds = new Set(progressData.completedLessons || []);

          const firstUncompletedLesson = lessonsArray.find(
            lesson => lesson.id && !completedLessonIds.has(lesson.id)
          );
  
          if (firstUncompletedLesson) {
            setLessonIdToNavigate(firstUncompletedLesson.id);
            setButtonText(t('continue_learning'));
          } else {
            setLessonIdToNavigate(lessonsArray[0]?.id || null);
            setButtonText(t('start_learning'));
          }
        } catch (error) {
          if (error.response.status === 404) {
            setLessonIdToNavigate(lessonsArray[0]?.id || null);
            setButtonText(t('start_learning'));
            return;
          }

          // re-throw the error if it's not a 404
          throw error;
        }
      } catch (error) {
        console.error('Error fetching lessons or course progress:', error);
      }
    };

    fetchLessonsAndProgress();
  }, [courseId, userId, t]);

  return { lessonIdToNavigate, buttonText };
};

export default useLessonProgress;