import { useState, useEffect } from 'react';
import useAuthTokens from 'components/auth/auth0/useAuthTokens';
import API from 'api/API';
import Content from 'api/Content';

const useFetchUserCoursesInProgress = (userId, allCourses) => {
    const [coursesInProgress, setCoursesInProgress] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { accessToken } = useAuthTokens();

    const fetchUserCoursesInProgress = async () => {
        if (!userId || allCourses.length === 0) return;

        try {
            setLoading(true);
            setError(null);

            const userCoursesData = await API.get(`/users/api/v1/users/${userId}/courses`);
            if (userCoursesData === null) return [];

            const userCourseIds = userCoursesData.map(course => course.courseId);
            const userCourses = allCourses.filter(course => userCourseIds.includes(course.id));

            const coursesWithProgress = await Promise.all(
                userCourses.map(async (course) => {
                    const progressData = await API.get(`/users/api/v1/users/${userId}/courses/${course.id}`);
                    const lessonsData = await Content.get(`/courses/${course.id}/lessons`);

                    const contentLessonIds = lessonsData.sections.flatMap(section => section.lessons.map(lesson => lesson.id));
                    const validCompletedLessons = progressData.completedLessons.filter(lessonId => contentLessonIds.includes(lessonId));

                    const progressPercentage = (validCompletedLessons.length / contentLessonIds.length) * 100;
                    return { ...course, progress: progressPercentage };
                })
            );

            setCoursesInProgress(coursesWithProgress.filter(Boolean));
        } catch (error) {
            console.error(error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUserCoursesInProgress();
    }, [userId, allCourses]);

    return { coursesInProgress, loading, error };
};

export default useFetchUserCoursesInProgress;