import { useState, useEffect } from 'react';
import API from 'api/API';

const useActiveSubscription = (userId) => {
  const [hasActiveSubscription, setHasActiveSubscription] = useState(false);

  useEffect(() => {
    const checkSubscription = async () => {
      try {
        const data = await API.get(`/subscription/api/v1/subscriptions/${userId}/active`);
        const now = new Date();
        const hasActive = data.some((sub) => new Date(sub.expiresAt) > now);
        setHasActiveSubscription(hasActive);
      } catch (error) {
        console.error('Failed to fetch subscription:', error);
        setHasActiveSubscription(false);
      }
    };

    if (userId) {
      checkSubscription();
    }
  }, [userId]);

  return hasActiveSubscription;
};

export default useActiveSubscription;