import React, { useEffect, useState } from 'react';
import { useSessionStorage } from 'react-use';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ArrowLeft } from 'react-bootstrap-icons';
import clickLogo from 'assets/images/payment/click.png';
import { displayPrice } from 'helper/priceHelpers';
import { getUser } from 'components/auth/Authenticated';
import usePayment from 'hooks/usePayment';
import useFetchSubscriptions from 'hooks/useFetchSubscriptions';
import mixpanel from "analytics/mixpanel";

const PAYMENT_METHODS = {
  CLICK: 'CLICK_UZ',
};

const SUCCESS_URLS = {
  [PAYMENT_METHODS.CLICK]: `${window.location.origin}/payment-result/click`,
};

const isValidPaymentMethod = (method) => Object.keys(SUCCESS_URLS).includes(method);

const getSuccessUrl = (method) => SUCCESS_URLS[method];

const PaymentPage = () => {
  const user = getUser();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedPlan] = useSessionStorage('selectedPlan');
  const [selectedMethod, setSelectedMethod] = useState('');
  const { initiatePayment, loading, error } = usePayment();
  const { subscriptions, loading: subscriptionsLoading } = useFetchSubscriptions(user);

  useEffect(() => {
    if (!selectedPlan) {
      navigate('/pricing');
      return;
    }

    if (subscriptions.length > 0) {
      navigate('/pricing');
      return;
    }
  }, [selectedPlan, subscriptions, navigate]);

  useEffect(() => {
    mixpanel.track_pageview({
        "page": "Payment Page",
    });
  }, []);

  const handleConfirm = async () => {
    if (!isValidPaymentMethod(selectedMethod)) {
      alert(t('alert_select_payment_method'));
      return;
    }

    mixpanel.track('Button Click', {
      button: 'Confirm Payment Button Clicked',
      plan_name: selectedPlan?.name,
    });

    const paymentData = {
      userId: user?.id,
      planId: selectedPlan?.id,
      durationDays: selectedPlan?.duration,
      paymentMethod: selectedMethod,
      successUrl: getSuccessUrl(selectedMethod),
    };
    
    try {
      const data = await initiatePayment(paymentData);

      if (data?.sessionURL) {
        window.location.href = data.sessionURL;
      } else {
        alert(t('error_creating_payment_session'));
      }
    } catch (err) {
      alert(err || t('error_creating_payment_session'));
    }
  };

  if (subscriptionsLoading) {
    return <p>{t('loading')}</p>;
  }

  return (
    <Container className="my-5 mt-15">
      <div className="d-flex align-items-center mb-5" style={{ cursor: 'pointer' }} onClick={() => navigate('/pricing')}>
        <div
          className="bg-light-primary rounded-circle d-flex align-items-center justify-content-center me-3"
          style={{ width: '36px', height: '36px' }}
        >
          <ArrowLeft className="text-primary" size={18} />
        </div>
        <h4 className="mb-0 fw-semibold fs-5">{t('select_pricing_plan')}</h4>
      </div>

      <Row className="justify-content-center align-items-stretch">
        <Col md={4} className="d-flex">
          <Card className="shadow-sm border border-primary w-100">
            <Card.Body className="p-5">
              <h3 className="fw-bold">{t('order_summary')}</h3>
              <hr />
              <div className="d-flex justify-content-between mb-3">
                <p className="fw-semibold">{t('plan')}</p>
                <p className="fw-semibold">{t('price')}</p>
              </div>
              <div className="d-flex justify-content-between">
                <p>{selectedPlan?.name}</p>
                <p>
                  {displayPrice(selectedPlan?.price?.amount)}{' '}
                  {selectedPlan?.price?.currency}
                </p>
              </div>
            </Card.Body>
            <div className="bg-light-primary rounded-bottom p-3">
              <div className="d-flex justify-content-between">
                <strong>{t('total_amount')}</strong>
                <strong>
                  {displayPrice(selectedPlan?.price?.amount)}{' '}
                  {selectedPlan?.price?.currency}
                </strong>
              </div>
            </div>
          </Card>
        </Col>

        <Col md={6} className="d-flex">
          <Card className="shadow-sm w-100">
            <Card.Body className="p-5">
              <h3 className="fw-bold">{t('select_payment_method')}</h3>
              <div className="d-flex align-items-center mb-4">
                <Form.Check
                  type="radio"
                  name="payment"
                  id="click"
                  checked={selectedMethod === PAYMENT_METHODS.CLICK}
                  onChange={() => setSelectedMethod(PAYMENT_METHODS.CLICK)}
                />
                <div
                  onClick={() => setSelectedMethod(PAYMENT_METHODS.CLICK)}
                  style={{ cursor: 'pointer', marginRight: '20px' }}
                >
                  <img src={clickLogo} alt="Click" style={{ height: '130px', width: '130px' }} />
                </div>
              </div>

              <Button
                onClick={handleConfirm}
                className="btn btn-primary w-30"
                disabled={!selectedMethod || loading}
              >
                {loading ? t('loading') : t('confirm')}
              </Button>
              {error && <div className="text-danger mt-2">{error}</div>}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PaymentPage;