import { useState, useEffect } from 'react';
import Content from 'api/Content';

const useFetchAllCourses = () => {
    const [allCourses, setAllCourses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchAllCourses = async () => {
        try {            
            const data = await Content.get('/courses');

            setAllCourses(data.courses);
            setLoading(false);
        } catch (error) {
            console.error(error);
            setError(error.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAllCourses();
    }, []);

    return { allCourses, loading, error };
};

export default useFetchAllCourses;