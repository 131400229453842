import { useEffect, useState } from 'react';
import API from 'api/API';

const useFetchPricingPlans = () => {
  const [plans, setPlans] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPricingPlans = async () => {
      try {
        const data = await API.get('/subscription/api/v1/plans');
        setPlans(data);
      } catch (error) {
        console.error('Error fetching plans:', error);
        setError(error);
      }
    };

    fetchPricingPlans();
  }, []);

  return { plans, error };
};

export default useFetchPricingPlans;