import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'react-use';

const PaymentSuccess = () => {
  const { t } = useTranslation();

  const [selectedPlan] = useSessionStorage('selectedPlan');
  const planName = selectedPlan?.name; 

  const handleContinue = () => {
    const redirectUrl = sessionStorage.getItem('redirectAfterPayment') || '/';
    sessionStorage.removeItem('redirectAfterPayment'); 
    window.location.href = redirectUrl;
  };

  return (
    <div
      className="d-flex flex-column align-items-center"
      style={{
        height: '100vh',
        backgroundColor: '#fff',
        paddingTop: '25vh',
      }}
    >
      <div className="mb-3">
        <span role="img" aria-label="peace" style={{ fontSize: '48px' }}>
          ✌️
        </span>
      </div>
      <h1 className="fw-bold mb-2">{t('paymentSuccess')}</h1>
      {planName ? (
        <h3 className="text mb-4">{t('subscriptionActiveForPlan', { planName })}</h3>
      ) : (
        <h3 className="text mb-4">{t('subscriptionActive')}</h3>
      )}
      <button className="btn btn-primary btn-lg" onClick={handleContinue}>
        {t('continue')}
      </button>
    </div>
  );
};

export default PaymentSuccess;