import axios from "axios";

const Content = axios.create({
  baseURL: process.env.REACT_APP_CONTENT_API_BASE_URL, // Base URL
  timeout: 10000, // Timeout
});

Content.interceptors.response.use(
    (response) => response.data, // Return data directly
    (error) => {
      if (error.response) {
        // Server responded with an error
        console.error(`Error ${error.response.status}:`, error.response.data);
      } else {
        console.error("Network/Timeout error:", error.message);
      }
      return Promise.reject(error); // Propagate the error
    }
  );


export default Content;