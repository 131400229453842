import { useEffect } from "react";
import mixpanel from "analytics/mixpanel";

export const useDurationTracker = (pageName) => {
  useEffect(() => {
    const start = Date.now(); 
    return () => {
      const durationInSeconds = (Date.now() - start) / 1000;
      mixpanel.track_pageview({
        page: pageName, 
        view_duration_seconds: durationInSeconds,
      });
    };
  }, []); 
};

