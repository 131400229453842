import { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { Image, Navbar, Nav, Container } from 'react-bootstrap';
import { getUser, logout } from 'components/auth/Authenticated';
import { useNavigate } from 'react-router-dom';

// Import sub layout components
import NavDropdownMain from 'components/layouts/navbar/NavDropdownMain';

// Import media files
import Logo from 'assets/images/logo/logo-blue.svg';

// Import data files
import NavbarDefaultRoutes from 'routes/NavbarDefault';

const NavbarDefault = ({ headerstyle = 'navbar-default', isLoginEnabled = false }) => { 
    const user = getUser();

    const [expandedMenu, setExpandedMenu] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    const logoStyles = {
        width: '50px',
        height: '50px'
    };

    useEffect(() => {
        setExpandedMenu(false);
    }, [location]);

    const handleAuthClick = (e) => {
        e.preventDefault();
        if (user) {
            logout(window.location.origin);
            window.location.href = window.location.origin;
        } else {
            navigate('/auth');
        }
    };

    return (
        <Fragment>
            <Navbar
                onToggle={(collapsed) => setExpandedMenu(collapsed)}
                expanded={expandedMenu}
                expand="lg"
                className={`navbar p-2 ${headerstyle} py-2 fixed-top`}
                style={{ zIndex: '1030' }}
            >
                <Container fluid className="px-0">
                    <Navbar.Brand 
                        href="https://xorazmiy.dev" 
                        className="ps-lg-8"
                        style={{ textDecoration: 'none' }} 
                    >
                        <Image src={Logo} alt="Logo" style={logoStyles} />
                    </Navbar.Brand>

                    <Navbar.Toggle aria-controls="basic-navbar-nav">
                        <span className="icon-bar top-bar mt-0"></span>
                        <span className="icon-bar middle-bar"></span>
                        <span className="icon-bar bottom-bar"></span>
                    </Navbar.Toggle>

                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav>
                            {NavbarDefaultRoutes.map((item, index) =>
                                item.children ? (
                                    <NavDropdownMain
                                        item={item}
                                        key={index}
                                        onClick={(value) => setExpandedMenu(value)}
                                    />
                                ) : (
                                    <Nav.Link key={index} as={Link} to={item.link}>
                                        {item.menuitem}
                                    </Nav.Link>
                                )
                            )}
                        </Nav>
                        <Nav className="ms-auto">
                            <li className="nav-item d-none d-lg-block">
                                <a
                                    className="btn btn-outline-primary ms-3 me-8 fw-bold fs-6"
                                    href="#"
                                    onClick={handleAuthClick}
                                >
                                    {user ? 'Chiqish' : 'Kirish'}
                                </a>
                            </li>
                            <Nav.Link
                                href="#"
                                onClick={handleAuthClick}
                                className="d-lg-none text-center py-2 text-primary fw-bold"
                                style={{ fontSize: '1rem' }}
                            >
                                {user ? 'Chiqish' : 'Kirish'}
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <style>
                {`
                    body {
                        padding-top: 70px;
                    }
                `}
            </style>
        </Fragment>
    );
};

// Typechecking With PropTypes
NavbarDefault.propTypes = {
    headerstyle: PropTypes.string,
    isLoginEnabled: PropTypes.bool
};

export default NavbarDefault;