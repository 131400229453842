import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PaymentSuccess from 'components/payment/PaymentSuccess';
import PaymentFailure from 'components/payment/PaymentFailure';

const STATUS_SUCCESS = 'success';
const STATUS_FAILURE = 'failure';

const PAYMENT_STATUS_SUCCESS = 2;
const PAYMENT_STATUS_FAILURE_THRESHOLD = 0;

const ClickPaymentResultPage = () => {
  const [paymentStatus, setPaymentStatus] = useState(null); 
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const status = parseInt(queryParams.get('payment_status'), 10);

    if (status === PAYMENT_STATUS_SUCCESS) {
      setPaymentStatus(STATUS_SUCCESS); 
    } else if (status < PAYMENT_STATUS_FAILURE_THRESHOLD) {
      setPaymentStatus(STATUS_FAILURE); 
    } else {
      navigate('/pricing');
    }
  }, [navigate]);

  if (paymentStatus === null) {
    return <div>{t('loading')}</div>; 
  }

  return paymentStatus === STATUS_SUCCESS ? <PaymentSuccess /> : <PaymentFailure />;
};

export default ClickPaymentResultPage;