import API from 'api/API';

const checkCourseExists = async (userId, accessToken, courseId) => {
  try {
    const res = await API.get(`/users/api/v1/users/${userId}/courses/${courseId}`);
    return res !== null; 
  } catch (error) {
    console.error("Error checking course existence:", error);
    return false;
  }
};

const initializeCourseProgress = async (userId, accessToken, courseId) => {
  try {
    await API.post(`/users/api/v1/users/${userId}/courses/${courseId}/lessons/completed`, []);
  } catch (error) {
    console.error('Error initializing course progress:', error);
  }
};

export const setupCourseIfNotExists = async (userId, accessToken, courseId) => {
  const courseExists = await checkCourseExists(userId, accessToken, courseId);
  if (!courseExists) {
    await initializeCourseProgress(userId, accessToken, courseId);
  }
};