import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PaymentFailure = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div
      className="d-flex flex-column align-items-center"
      style={{
        height: '100vh',
        backgroundColor: '#fff',
        paddingTop: '25vh',
      }}
    >
      <div className="mb-3">
        <span role="img" aria-label="sad" style={{ fontSize: '48px', color: '#dc3545' }}>
          😞
        </span>
      </div>
      <h1 className="fw-bold mb-2" style={{ color: '#dc3545' }}>{t('paymentFailed')}</h1>
      <h3 className="text mb-4">{t('somethingWentWrong')}</h3>
      <button
        className="btn btn-primary btn-lg"
        onClick={() => navigate('/pricing')}
      >
        {t('tryAgain')}
      </button>
    </div>
  );
};

export default PaymentFailure;