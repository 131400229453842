import React from 'react';
import { Route, Routes } from 'react-router-dom';
import 'assets/scss/theme.scss';

import DefaultLayout from 'components/layouts/DefaultLayout';
import CoursePage from 'pages/CoursePage';
import LessonPage from 'pages/LessonPage';
import StudentPage from 'pages/StudentPage';
import StudentSubscriptions from 'components/account-settings/Subscriptions';
import EditProfile from 'components/account-settings/EditProfile';
import Dashboard from 'pages/StudentDashboard';
import PricingPage from 'pages/PricingPage';
import PaymentPage from 'pages/PaymentPage';
import Authenticated from 'components/auth/Authenticated';
import AuthPage from 'components/auth/AuthPage';
import ClickPaymentResultPage from 'pages/ClickPaymentResultPage';

const AllRoutes = ({ userId }) => {
  return (
    <>
        <Routes>
            <Route element={<DefaultLayout />}>
                    <Route
                        path="/"
                        element={<Dashboard />}
                    />
                    <Route
                        path="/courses/:courseId"
                        element={<CoursePage />}
                    />
                    <Route
                        path="/pricing"
                        element={<PricingPage />}
                    />
                    <Route
                        path="/payment"
                        element={<Authenticated Component={PaymentPage} />}
                    />     
                    <Route path="/payment-result/click" 
                        element={<ClickPaymentResultPage />}     
                    />
                    <Route
                        exact path="/courses/:courseId/:lessonId"
                        element={<Authenticated Component={LessonPage} />}
                    />
                    <Route
                        path="/student/page/"
                        element={<StudentPage />}
                    />
                    <Route
                        path="/student/student-subscriptions/"
                        element={<StudentSubscriptions />}
                    />
                    <Route
                        path="/student/student-edit-profile/"
                        element={<EditProfile />}
                    />
                    <Route
                        path="/auth"
                        element={<Authenticated Component={AuthPage} />}
                    />
            </Route>
        </Routes>
    </>
  );
};

export default AllRoutes;