import { useState, useEffect } from 'react';
import API from 'api/API';

const useLessonCompletion = (lessonId, courseId, user, setCompletedLessons) => {
  const [isLessonCompleted, setIsLessonCompleted] = useState(false);

  useEffect(() => {
    const fetchCompletedLessons = async () => {
      if (!user) {
        return;
      }

      try {
        const data = await API.get(`/users/api/v1/users/${user.id}/courses/${courseId}`);
        const completedLessonIds = data.completedLessons || [];
        setCompletedLessons(completedLessonIds);
        setIsLessonCompleted(completedLessonIds.includes(lessonId));
      } catch (error) {
        console.error('Error fetching lesson completion:', error.message);
      }
    };

    fetchCompletedLessons();
  }, [lessonId]);

  const toggleCompletion = async (checked) => {
    if (!user) {
      return;
    }

    setIsLessonCompleted(checked);
    const lessonArray = [lessonId];

    try {
      if (checked) {
        // throws if the request fails
        await API.post(`/users/api/v1/users/${user.id}/courses/${courseId}/lessons/completed`, lessonArray);
        setCompletedLessons((prev) => [...prev, lessonId]);
      } else {
        await API.delete(`/users/api/v1/users/${user.id}/courses/${courseId}/lessons/completed`, {
          data: lessonArray, 
        });
        setCompletedLessons((prev) => prev.filter((id) => id !== lessonId));
      }
    } catch (error) {
      console.error('Error toggling lesson completion:', error.message);
    }
  };

  return { isLessonCompleted, toggleCompletion };
};

export default useLessonCompletion;