import { useState } from 'react';
import API from 'api/API';

const usePayment = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const initiatePayment = async (paymentData) => {
    setLoading(true);
    setError(null);

    try {
      return await API.post('/subscription/api/v1/payments', paymentData);
    } catch (err) {
      setError(err.message);
      throw err; 
    } finally {
      setLoading(false);
    }
  };

  return { initiatePayment, loading, error };
};

export default usePayment;